// Small desktops 992px
$screen-lg: 1056px; //66rem

@mixin lg {
    @media (min-width: #{$screen-lg}) {
        @content;
    }
 }

.signin-box {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-right: 24px;
    text-decoration: none !important; //override the main styles when app is embedded or carbon styles
    outline: none !important; //override the main styles when app is embedded or carbon styles

    @include lg {
        height: 48px;
        width: 204px;
        border-radius: 6px;
        background-color: #1063A2;
        flex-direction: row;
        justify-content: flex-start;
        padding: 12px 24px;
        margin-right: 0;
        transition: 0.333333333s ease-in-out;
    }

    &__user-box-icon {
        margin-bottom: 8px;

        @include lg {
            margin-bottom: 0;
            margin-right: 8px;
        }

        .user-icon{
            display:none;

            @include lg {
                display:block;
            }
        }

        .user-icon-m {
            display:block;

            @include lg {
                display:none;
            }
        }
    }

    &__sign-in-sign-up {
        display: none;

        @include lg {
            display: block;
            color: #FFFFFF;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-transform: capitalize;
        }
    }

    &__sign-in-sign-up-m{
        display: block;
        color: #0e75c4;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1.25px;
        text-transform: uppercase;

        @include lg {
            display: none;
        }
    }
}

.signout-box {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin-right: 24px;
    text-decoration: none !important; //override the main styles when app is embedded or carbon styles
    outline: none !important; //override the main styles when app is embedded or carbon styles

    @include lg {
        height: 48px;
        width: 144px;
        border-radius: 6px;
        background-color: #1063A2;
        flex-direction: row;
        padding: 12px 24px;
        transition: 0.333333333s ease-in-out;
        margin-right: 0;
    }

    &__user-box-icon {
        margin-bottom: 8px;

        @include lg {
            margin-bottom: 0;
            margin-right: 8px;
        }

        .user-icon{
            display:none;

            @include lg {
                display:block;
            }
        }

        .user-icon-m {
            display:block;

            @include lg {
                display:none;
            }
        }
    }

    &__signout {
        display: block;
        color: #0e75c4;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1.25px;
        text-transform: uppercase;

        @include lg {
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0;
            text-transform: capitalize;
        }
    }

}

.signin-box-off {
    display : none;
}

.signout-box-off {
    display: none;
}

.signin-box:hover {
    text-decoration: none;

    @include lg {
        background-color: #105a92;
    }
}

.signout-box:hover {
    text-decoration: none;

    @include lg {
        background-color: #105a92;
    }
}